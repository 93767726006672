<template>
  <div class="main-wrapper">
    <Sidebar ref="sidebar"/>
    <div class="page-wrapper">
      <Navbar ref="navbar" @toggle-sidebar="toggleSidebar" />
      <slot />
      <Footer />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardLayout',
  data() {
    return {
      loaderStack: 0,
      loader: null,
    };
  },
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.toggleSidebar();
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
  components: {
    Footer: () => import('../components/Footer.vue'),
    Navbar: () => import('../components/Navbar.vue'),
    Sidebar: () => import('../components/Sidebar.vue'),
  },
};
</script>
